<template>
    <base-modal :title="title">
        <v-progress-linear v-if="loading" class="my-0"
                           height="2" indeterminate/>
        <template v-else>
            <v-card-text>
                <v-form ref="form" v-model="formIsValid">
                    <v-container class="pa-0" grid-list-md>

                        <v-layout class="mt-2" row
                                  wrap>
                            <v-flex class="py-0" xs12>
                                <v-layout row wrap>
                                    <v-flex class="py-0" md6 xs12>
                                        <!-- select bookable item-->
                                        <v-select v-model="form.bookableItemId"
                                                  :items="bookable_items"
                                                  :rules="[rules.required]"
                                                  item-text="title"
                                                  item-value="id"
                                                  label="Wat wordt er geboekt?"/>
                                    </v-flex>
                                    <v-flex class="py-0" md6 xs12>
                                        <!-- select date-->
                                        <v-menu
                                            ref="menuDate"
                                            v-model="menuDate"
                                            :close-on-content-click="false"
                                            :return-value.sync="form.bookingDate"
                                            min-width="290px"
                                            offset-y
                                            transition="scale-transition"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="form.bookingDate"
                                                    v-on="on"
                                                    :rules="[rules.required]"
                                                    label="Datum"
                                                    prepend-icon="event"
                                                    readonly
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="form.bookingDate"
                                                           first-day-of-week="1" locale="nl"
                                                           :min="today"
                                                           no-title scrollable
                                                           @change="$refs.menuDate.save(form.bookingDate)">
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary" text @click="menuDate = false">Annuleren</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                    <v-flex class="py-0" md6 xs12>
                                        <!-- select start time-->
                                        <p class="headline">Starttijd</p>
                                        <vue-timepicker format="HH:mm"
                                                        :hour-range="[[6, 23]]" hide-disabled-hours
                                                        v-model="form.startTime"
                                                        manual-input
                                                        @change="form.endTime = null"></vue-timepicker>
                                    </v-flex>
                                    <v-flex class="py-0" md6 xs12>
                                        <!-- select end time-->
                                        <p class="headline">Eindtijd</p>
                                        <vue-timepicker format="HH:mm"
                                                        :hour-range="[[6, 23]]" hide-disabled-hours
                                                        v-model="form.endTime"
                                                        manual-input
                                                        :disabled="form.startTime === null"></vue-timepicker>
                                    </v-flex>
                                    <v-flex class="py-0" md12 xs12>
                                        <v-text-field v-model="form.description"
                                                      :rules="[rules.required]"
                                                      label="Omschrijving"/>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <p class="mt-3 ml-3 red--text"></p>
                <v-spacer/>
                <v-btn class="red--text" text @click="$emit('closeModal')">
                    Annuleren
                </v-btn>
                <v-btn :disabled="!formIsValid" color="green" text @click="saveUser">
                    Opslaan
                </v-btn>
            </v-card-actions>
        </template>
    </base-modal>
</template>

<script>

import VueTimepicker from 'vue2-timepicker'

import BaseModal from '../../templates/BaseModal';
import API from '../../helpers/api';
import {validationRules} from '../../mixins/validationRulesMixin';
import NotificationHelper from "../../helpers/notifications";
import dayjs from "dayjs";

export default {
    name: 'BookingCreateModal',
    components: {
        BaseModal, VueTimepicker
    },
    mixins: [validationRules],
    data() {
        return {
            formIsValid: false,
            loading: true,
            menuDate: false,
            form: {
                bookingDate: null,
                startTime: null,
                endTime: null,
                bookableItemId: null,
                description: null
            },
            bookable_items: []
        }
    },
    computed: {
        title() {
            return `Reservering maken`;
        },
        today() {
            return dayjs().format('YYYY-MM-DD')
        }
    },
    methods: {
        saveUser() {
            API.createBooking(this.form)
                .then(() => {
                    NotificationHelper.showSuccess('Reservering opgeslagen');
                    this.$emit('closeModal');
                })
                .catch(err => NotificationHelper.showError(err.response.data.message));
        },
    },
    async mounted() {
        await API.getBookableItems()
            .then(response => this.bookable_items = response.data);

        this.loading = false;
    }
};
</script>

<style lang="scss">
    @import '~vue2-timepicker/dist/VueTimepicker.css';
</style>
