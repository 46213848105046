<template>
	<base-modal :title="title">
		<v-progress-linear v-if="loading" indeterminate
		                   class="my-0" height="2" />
		<template v-else>
			<v-card-text>
				<v-form ref="form" v-model="formIsValid">
					<v-container grid-list-md class="pa-0">
						<v-layout row wrap
						          class="mt-2">
							<v-flex xs12 class="py-0">
								<v-layout row wrap>
									<v-flex xs12 md6 class="py-0">
										<v-text-field
									        v-model="form.title"
									        :rules="[rules.required]"
									        prepend-icon="mdi-card-text-outline"
									        label="Titel" />
							        </v-flex>

							        <v-flex xs12 md6 class="py-0">
							          	<v-select
							          		:items="priorities"
							          		prepend-icon="mdi-fire"
								            v-model="form.priority"
								            :rules="[rules.required]"
								            label="Prioriteit"
								            item-value="id"
								            item-text="name" />
								    </v-flex>

								    <v-flex xs12 md12 class="py-0">
                                        <p class="title">Inhoud (max 2000 tekens)</p>
                                        <content-editor v-model="form.description" />
							        </v-flex>

									<v-flex xs12 md12 class="py-0">
									        <!-- :rules="[rules.urlOrEmpty]" -->
										<v-text-field
									        v-model="form.url"
									        prepend-icon="mdi-web"
									        hint="De link moet altijd beginnen met http:// of https://"
									        persistent-hint
									        label="Link" />
							        </v-flex>

								    <v-flex xs12 md12 class="py-0 mt-3">
							        	<p class="title">Weergaveduur instellen</p>
							        </v-flex>
									<v-flex xs12 md6 class="py-0">
							          	<v-menu
									        ref="menuStart"
									        v-model="menuStart"
									        :close-on-content-click="false"
									        :return-value.sync="form.display_start"
									        transition="scale-transition"
									        offset-y
									        min-width="290px"
									      >
									        <template v-slot:activator="{ on }">
									          <v-text-field
									            v-model="form.display_start"
									            label="Vanaf"
									            :rules="[rules.required]"
									            prepend-icon="event"
									            readonly
									            v-on="on"
									          ></v-text-field>
									        </template>
									        <v-date-picker v-model="form.display_start" @change="$refs.menuStart.save(form.display_start)" no-title scrollable locale="nl" first-day-of-week="1">
									          <v-spacer></v-spacer>
									          <v-btn text color="primary" @click="menuStart = false">Annuleren</v-btn>

									        </v-date-picker>
									      </v-menu>
								    </v-flex>

									<v-flex xs12 md6 class="py-0">
							          	<v-menu
									        ref="menuEnd"
									        v-model="menuEnd"
									        :close-on-content-click="false"
									        :return-value.sync="form.display_end"
									        transition="scale-transition"
									        offset-y
									        min-width="290px"
									      >
									        <template v-slot:activator="{ on }">
									          <v-text-field
									            v-model="form.display_end"
									            label="Tot"
									            :rules="[rules.required]"
									            prepend-icon="event"
									            readonly
									            v-on="on"
									          ></v-text-field>
									        </template>
									        <v-date-picker v-model="form.display_end" no-title scrollable @change="$refs.menuEnd.save(form.display_end)" locale="nl" first-day-of-week="1">
									          <v-spacer></v-spacer>
									          <v-btn text color="primary" @click="menuEnd = false">Annuleren</v-btn>
									          <!-- <v-btn text color="primary" @click="$refs.menuEnd.save(form.display_end)">OK</v-btn> -->
									        </v-date-picker>
									      </v-menu>
								    </v-flex>

								</v-layout>
							</v-flex>
						</v-layout>
					</v-container>
				</v-form>
			</v-card-text>

			<v-divider/>

			<v-card-actions>
				<p class="mt-3 ml-3 red--text"></p>
				<v-spacer/>
				<v-btn text class="red--text" @click="$emit('closeModal')">
					Annuleren
				</v-btn>
				<v-btn text color="green" :disabled="!formIsValid" @click="saveAnnouncement">
					Opslaan
				</v-btn>
			</v-card-actions>
		</template>
	</base-modal>
</template>

<script>
	import BaseModal from '../../templates/BaseModal';
	import API from '../../helpers/api';
	import {validationRules} from '../../mixins/validationRulesMixin';
    import dayjs from "dayjs";
    import NotificationHelper from "../../helpers/notifications";
    import ContentEditor from "../ContentEditor";

	export default {
		name: 'AnnouncementEditModal',
		components: {
            ContentEditor,
			BaseModal
		},
		props: {
			announcementId: {
				type: Number
			}
		},
		mixins: [validationRules],
		data() {
			return {
				formIsValid: false,
				loading: false,
				menuStart: false,
				menuEnd: false,
				form: {
					display_start: dayjs().format('YYYY-MM-DD'),
					display_end: dayjs().add(7, 'days').format('YYYY-MM-DD'),
					url: 'https://',
					title: null,
					description: null,
					priority: 2
				},
				priorities: [
					{ id: 1, name: 'Hoog' },
					{ id: 2, name: 'Laag' }
				]
			}
		},
		computed: {
			isCreateMode() {
				return this.announcementId === undefined;
			},
			title() {
				return `Mededeling ${this.isCreateMode ? 'aanmaken' : 'bewerken'}`;
			}
		},
		methods: {
			saveAnnouncement() {
				if(!this.formIsValid) {
					NotificationHelper.showGenericError();
					return;
				}

				if (dayjs(this.form.display_start).diff(dayjs(this.form.display_end), 'days') > 0) {
					NotificationHelper.showError("Error", "De vanaf-datum moet kleiner zijn dan de tot-datum!");
					return;
				}

				this.loading = true;

				this.getApiCall()
					.then(() => {
						NotificationHelper.showSuccess('Mededeling opgeslagen', "De mededeling is succesvol opgeslagen");
						this.$emit('closeModal');
					})
					.catch((err) => {
						NotificationHelper.showError('Error', err.response.data.error);
						this.loading = false;
					});
			},
			getApiCall() {
				if (!this.isCreateMode) {
					return API.updateAnnouncement(this.getPostObject(), this.announcementId);
				}

				return API.createAnnouncement(this.getPostObject());
			},
			loadAnnouncement() {
				this.loading = true;

				API.getAnnouncement(this.announcementId)
					.then(response => {
                        this.form = response.data;
                        this.form.display_end = dayjs(this.form.display_end).format('YYYY-MM-DD');
                        this.form.display_start = dayjs(this.form.display_start).format('YYYY-MM-DD');
                    })
					.catch(() => NotificationHelper.showGenericError())
					.finally(() => this.loading = false);
			},
			getPostObject() {
				let dataObject = new FormData();

				this.form.display_end = this.form.display_end.slice(0, 10);
				this.form.display_start = this.form.display_start.slice(0, 10);

				dataObject.append('display_start', this.form.display_start);
				dataObject.append('display_end', this.form.display_end);
				dataObject.append('url', this.form.url);
				dataObject.append('title', this.form.title);
				dataObject.append('description', this.form.description);
				dataObject.append('priority', this.form.priority);

				return dataObject;
			}
		},
		mounted() {
			if (!this.isCreateMode) {
				this.loadAnnouncement();
			}
		}
	};
</script>
