<template>
    <div>
        <page-header-toolbar title="Mededelingen">
            <template slot="buttons">
                <v-btn class="white--text" text v-if="$store.state.user.is_admin === 1"
                       @click="showAnnouncementCreateModal = true">
                    Mededeling aanmaken
                </v-btn>
            </template>
        </page-header-toolbar>

        <!-- CREATE MODAL -->
        <announcement-edit-modal v-if="showAnnouncementCreateModal"
                                 v-on:closeModal="closeModal"/>

        <!-- EDIT MODAL -->
        <announcement-edit-modal v-if="showAnnouncementEditModal"
                                 :announcement-id="announcementToEdit.id"
                                 v-on:closeModal="closeModal"/>

        <v-progress-linear v-if="loading" class="my-0"
                           height="2" indeterminate/>

        <announcement-overview-table v-else
                                     :announcements="announcements"
                                     v-on:deleteAnnouncement="deleteAnnouncement"
                                     v-on:editAnnouncement="editAnnouncement"/>

    </div>
</template>

<script>

import AnnouncementOverviewTable from "../components/announcements/AnnouncementOverviewTable";
import PageHeaderToolbar from "../components/menus/PageHeaderToolbar";
import AnnouncementEditModal from "../components/announcements/AnnouncementEditModal";
import API from "../helpers/api";
import NotificationHelper from "../helpers/notifications";

export default {
    name: 'AnnouncementOverview',
    components: {
        AnnouncementOverviewTable,
        PageHeaderToolbar,
        AnnouncementEditModal
    },
    data() {
        return {
            showAnnouncementCreateModal: false,
            showAnnouncementEditModal: false,
            announcementToEdit: null,
            announcements: [],
            loading: false
        }
    },
    methods: {
        getAnnouncements() {
            this.loading = true;

            API.getAnnouncements()
                .then(data => this.announcements = data.data)
                .catch(() => NotificationHelper.showGenericError())
                .finally(() => this.loading = false);
        },
        editAnnouncement(announcement) {
            this.announcementToEdit = announcement;
            this.showAnnouncementEditModal = true;
        },
        deleteAnnouncement(announcement) {
            this.loading = true;

            API.deleteAnnouncement(announcement.id)
                .then(data => {
                    NotificationHelper.showSuccess('Mededeling verwijderd', 'Mededeling succesvol verwijderd!');
                    this.getAnnouncements();
                })
                .catch(() => {
                    this.loading = false;
                    NotificationHelper.showGenericError()
                });
        },
        closeModal() {
            this.showAnnouncementCreateModal = false;
            this.showAnnouncementEditModal = false;
            this.announcementToEdit = null;
            this.getAnnouncements();
        }
    },
    mounted() {
        this.getAnnouncements();
    }
}
</script>
