<template>
    <div>
        <page-header-toolbar title="Korpsleden">
            <template slot="buttons">
                <v-btn @click="showUserCreateModal = true" class="white--text" text v-if="$store.state.user.is_admin === 1">
                    Korpslid aanmaken
                </v-btn>
            </template>
        </page-header-toolbar>

        <!-- CREATE MODAL -->
        <user-edit-modal v-if="showUserCreateModal"
                         v-on:closeModal="closeModal" />

        <!-- EDIT MODAL -->
        <user-edit-modal v-if="showUserEditModal"
                         :user-id="userToEdit.id"
                         v-on:closeModal="closeModal" />

        <v-progress-linear v-if="loading" indeterminate
                           class="my-0" height="2" />

        <user-overview-table v-if="!loading"
                             v-on:editUser="editUser"
                             v-on:deleteUser="deleteUser"
                             :users="users" />

    </div>

</template>

<script>

import NotificationHelper from "../helpers/notifications";
import API from "../helpers/api";
import UserEditModal from "../components/users/UserEditModal";
import PageHeaderToolbar from "../components/menus/PageHeaderToolbar";
import UserOverviewTable from "../components/users/UserOverviewTable";

export default {
    name: 'UserOverview',
    components: {
        UserOverviewTable,
        PageHeaderToolbar,
        UserEditModal
    },
    data() {
        return {
            showUserCreateModal: false,
            showUserEditModal: false,
            userToEdit: null,
            users: [],
            loading: false
        }
    },
    methods: {
        getUsers() {
            this.loading = true;

            API.getUsers()
                .then(data => this.users = data.data)
                .catch(() => NotificationHelper.showGenericError())
                .finally(() => this.loading = false);
        },
        editUser(user) {
            this.userToEdit = user;
            this.showUserEditModal = true;
        },
        deleteUser(user) {
            this.loading = true;

            API.deleteUser(user.id)
                .then(data => {
                    NotificationHelper.showSuccess('Korpslid verwijderd', 'Het korpslid is succesvol verwijderd!');
                    this.getUsers();
                })
                .catch(err => {
                    this.loading = false;
                    NotificationHelper.showError('Error', err.response.data.error);
                });
        },
        closeModal() {
            this.showUserCreateModal = false;
            this.showUserEditModal = false;
            this.userToEdit = null;
            this.getUsers();
        }
    },
    mounted() {
        this.getUsers();
    }
}
</script>
