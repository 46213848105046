export const validationRules = {
	data() {
		return {
			rules: {
				required: value => !!value || "Verplicht veld",
				requiredArray: value => value.length > 0 || "Verplicht veld",
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return value === null || value.length === 0 || pattern.test(value) || "Onjuist e-mailadres";
				},
				url: (value) => {
					const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
					return value === null || value.length === 0 || pattern.test(value) || 'Ongeldige URL';
				},
				urlOrEmpty: (value) => {
					const pattern = /(^$)|(http(s?):\/\/)?(((www\.)?[a-zA-Z0-9.\-_]+(\.[a-zA-Z]{2,3})+))/;					
					return pattern.test(value) || 'Ongeldige URL';
				},
				urlOrIP: (value) => {
					const pattern = /^(http(s?):\/\/)?(((www\.)?[a-zA-Z0-9.\-_]+(\.[a-zA-Z]{2,3})+)|(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))(:[0-9]{1,4})?(\/[a-zA-Z0-9_\-\s./?%#&=]*)?$/i;
					return value === null || value.length === 0 || pattern.test(value) || 'Ongeldige URL of IP';
				},
				hoursAndMinutes: (value) => {
					const pattern = /(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
					return value === null || value.length === 0 || pattern.test(value) || 'Ongeldige tijd';
				},
				number: (value) => {
					const pattern = /^\d+$/;
					return value === null || value.length === 0 || pattern.test(value) || 'Ongeldig getal';
				}
			}
		};
	}
};
