<template>
	<v-dialog
			v-model="open"
			:width="getWidth"
			persistent
			data-cy="modal">
		<v-card>
			<v-card-title
					class="headline grey lighten-2"
					primary-title
					data-test-modal-title>
				{{title}}
				<v-spacer/>
				<v-btn v-if="withAction.action !== null" color="green" class="white--text" @click="withAction.action">
					{{ withAction.text }}
				</v-btn>
			</v-card-title>

			<slot></slot>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: 'BaseModal',
		props: {
			title: {
				type: String,
				default: ''
			},
			open: {
				type: Boolean,
				default: true
			},
			width: {
				type: String,
				default: '0'
			},
			withAction: {
				type: Object,
				default: () => {
					return {
						action: null,
						text: ''
					}
				}
			}
		},
		computed: {
			getWidth() {
				if(this.width !== '0') {
					return `${this.width}%`;
				}

				return '740px';
			}
		}
	};
</script>
