<template>
    <div>
        <page-header-toolbar title="Kazerne 66">
            <template slot="buttons">
                <v-btn v-if="$store.state.user === null" class="white--text" href="/login"
                       text>
                    Inloggen
                </v-btn>
            </template>
        </page-header-toolbar>

        <v-tabs v-model="activeTab">
            <v-tab>Reserveringen</v-tab>
            <v-tab>Mededelingen</v-tab>
            <v-tab-item>
                <tab-dashboard-bookings/>
            </v-tab-item>
            <v-tab-item>
                <tab-dashboard-announcements/>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import PageHeaderToolbar from "../components/menus/PageHeaderToolbar";
import TabDashboardBookings from "../components/dashboard/TabDashboardBookings";
import TabDashboardAnnouncements from "../components/dashboard/TabDashboardAnnouncements";

export default {
    name: "DashboardOverview",
    components: {TabDashboardAnnouncements, TabDashboardBookings, PageHeaderToolbar},
    data() {
        return {
            activeTab: 0
        }
    },
    mounted() {
        if (this.$route.query.tab === 'mededelingen') {
            this.activeTab = 1;
        }
    }
}
</script>

<style scoped>

</style>
