import Vue from 'vue';

const NotificationHelper = {
    showSuccess(title, text) {
        Vue.notify({
            group: 'alert',
            type: 'success',
            duration: 7000,
            title,
            text
        });
    },
    showError(title, text) {
        Vue.notify({
            group: 'alert',
            type: 'error',
            duration: 15000,
            title,
            text
        });
    },
    showWarning(title, text) {
        Vue.notify({
            group: 'alert',
            type: 'warning',
            duration: 12500,
            title,
            text
        });
    },
    showGenericError() {
        this.showError(`Unexpected error occurred`, `Please try again or contact support.`);
    }
};

export default NotificationHelper;
