import axios from 'axios';

export const createAxiosRequest = (url, method, data = null, hasFormData = false) => {
    const requestObject = {
        method: method,
        url: `${url}`
    };

    if (data !== null) {
        requestObject.data = data;

        if (hasFormData) {
            requestObject.headers = {
                'Content-Type': 'multipart/form-data'
            };
        }
    }

    return axios(requestObject);
};

export const createAxiosDownloadRequest = (url, method, data = null) => {
    const requestObject = {
        method: method,
        url: `${url}`,
        responseType: 'arraybuffer'
    };

    if (data !== null) {
        requestObject.data = data;
    }

    return axios(requestObject);
};

const getBaseUrl = () => {
    const currentBaseUrl = window.location.protocol + '//' + window.location.hostname;

    if (process.env.NODE_ENV === 'production') {
        if (process.env.MIX_APP_URL === currentBaseUrl) {
            return process.env.MIX_APP_URL;
        } else {
            return 'https://honselersdijk.webrew.nl';
        }
    }

    return process.env.MIX_APP_URL;
}

export const RM_BASE = getBaseUrl();

export const RM_SPA_BASE = `${RM_BASE}/spa-api`;
