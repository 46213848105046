<template>
	<div>
		<v-app app>

			<v-navigation-drawer app
			                     fixed
			                     dark
			                     permanent
                                 expand-on-hover
                                 v-model="drawer"
			                     :mini-variant.sync="drawer"
                                 color="#e8756a"
			                     width="280">

				<v-app-bar dark flat>
					<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

					<v-toolbar-title v-if="!drawer" class="hidden-sm-and-down">
						<router-link to="/auth/home" style="text-decoration: none; color: inherit;"
						             class="hidden-sm-and-down mr-4">
							Post Honselersdijk
						</router-link>
					</v-toolbar-title>
				</v-app-bar>

				<v-layout column style="height: calc(100% - 64px);">
					<main-menu :drawer="drawer" />

					<v-spacer />

					<user-menu :drawer="drawer" />
				</v-layout>
			</v-navigation-drawer>

			<v-main>

				<slot></slot>

			</v-main>
		</v-app>
	</div>
</template>
<script>
	import MainMenu from '../components/menus/MainMenu';
	import UserMenu from '../components/menus/UserMenu';

	export default {
		name: 'base-template',
		components: {
			MainMenu,
			UserMenu
		},
		data() {
			return {
				drawer: false
			};
		}
	};
</script>

