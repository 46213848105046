<template>
    <div>
        <page-header-toolbar title="Reserveringen">
            <template slot="buttons">
                <v-btn class="white--text" text
                       @click="showBookingCreateModal = true">
                    Reservering maken
                </v-btn>
            </template>
        </page-header-toolbar>

        <booking-create-modal v-if="showBookingCreateModal"
                              v-on:closeModal="closeModal"/>

        <v-btn
            class="ma-2"
            icon
            @click="$refs.calendar.prev()"
        >
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-chip>
            {{ getCurrentMonth }}
        </v-chip>
        <v-btn
            class="ma-2"
            icon
            @click="$refs.calendar.next()"
        >
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <!--        :first-interval= 9 // The first interval to display in the day view. If intervalMinutes is set to 60 and this is set to 9 the first time in the view is 9am.-->
        <!--        :interval-minutes= 60 // The number of minutes the intervals are in the day view. A common interval is 60 minutes so the intervals are an hour.-->
        <!--        :interval-count= 8 // The number of intervals to display in the day view.-->
        <v-calendar
            ref="calendar"
            v-model="focus"
            :categories="bookable_items"
            :event-color="getEventColor"
            :events="events"
            :is24hr="true"
            :first-interval="6"
            :interval-count="18"
            :interval-minutes="60"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            category-show-all
            color="primary"
            type="week"
            @change="dates => changeDates(dates)"
            @click:event="showEvent"
        ></v-calendar>

        <v-menu
            v-model="selectedOpen"
            :activator="selectedElement"
            :close-on-content-click="false"
            offset-x
        >
            <v-card
                color="grey lighten-4"
                flat
                min-width="350px"
            >
                <v-toolbar
                    :color="getEventColor(selectedEvent)"
                    dark
                >
                    <v-toolbar-title v-if="selectedEvent !== null" v-html="selectedEvent.name"></v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p><strong>Beschrijving:</strong></p>
                    <span v-if="selectedEvent !== null" v-html="selectedEvent.booking.description"></span>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="selectedOpen = false"
                    >
                        OK
                    </v-btn>
                    <v-btn text @click="deleteEvent(selectedEvent)">
                        <v-icon left>mdi-trash-can</v-icon> Verwijder
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import PageHeaderToolbar from "../components/menus/PageHeaderToolbar";
import API from "../helpers/api";
import dayjs from "dayjs";
import BookingCreateModal from "../components/bookings/BookingCreateModal";
import NotificationHelper from "../helpers/notifications";

const weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

export default {
    name: "BookingOverview",
    components: {BookingCreateModal, PageHeaderToolbar},
    data() {
        return {
            startDate: dayjs(),
            endDate: dayjs(),
            bookable_items: [],
            events: [],
            selectedEvent: null,
            selectedElement: null,
            selectedOpen: false,
            focus: '',
            showBookingCreateModal: false
        }
    },
    computed: {
        getCurrentMonth() {
            return dayjs(this.focus).format('MMMM');
        }
    },
    methods: {
        getEventColor(event) {
            if (event && event.booking && event.booking.bookable_item) {
                return event.booking.bookable_item.booking_type === 'VOERTUIG' ? 'green' : 'red';
            }

            return 'grey';
        },
        async changeDates(dates) {
            this.startDate = dates.start.date;
            this.endDate = dates.end.date;
            await this.fetchEvents();
        },
        async fetchEvents() {
            await API.getAllBookings(this.startDate, this.endDate)
                .then(response => {

                    this.events = response.data.map(booking => {
                        return {
                            name: booking.bookable_item.title + ' - ' + booking.user.name,
                            start: dayjs(booking.start).format('YYYY-MM-DD HH:mm'),
                            end: dayjs(booking.end).format('YYYY-MM-DD HH:mm'),
                            color: booking.bookable_item.booking_type === 'VOERTUIG' ? 'green' : 'red',
                            timed: true,
                            booking: booking
                        }
                    })
                })
        },
        showEvent({nativeEvent, event}) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        closeModal() {
            this.showBookingCreateModal = false;
            this.fetchEvents();
        },
        async deleteEvent(selectedEvent) {
            this.selectedOpen = false;

            if (!this.canDeleteEvent(selectedEvent)) {
                NotificationHelper.showError('Je kunt deze reservering niet verwijderen!');
                return;
            }

            await API.deleteBooking(selectedEvent.booking.id)
                .then(() => {
                    NotificationHelper.showSuccess('Reservering verwijderd.');
                })

            await this.fetchEvents();
        },
        canDeleteEvent(selectedEvent) {
            if (this.$store.state.user.is_admin === 1) {
                return true;
            }

            if (selectedEvent === null) {
                return false;
            }

            if (this.$store.state.user.id !== selectedEvent.booking.user.id) {
                NotificationHelper.showError('Je kunt niet de reservering van iemand anders verwijderen!');
                return false;
            }

            if (dayjs(selectedEvent.booking.end).isBefore(dayjs())) {
                NotificationHelper.showError('Je kunt geen oude reserveringen verwijderen!');
                return false;
            }

            return selectedEvent.booking.bookable_item.user_id !== this.$store.state.user.id;
        }
    },
    async mounted() {
        this.focus = dayjs().format('YYYY-MM-DD');
        this.startDate = dayjs().weekday(1).format('YYYY-MM-DD');
        this.endDate = dayjs().weekday(7).format('YYYY-MM-DD');

        await this.fetchEvents();
    }
}
</script>

<style scoped>

</style>
