<template>
    <v-app>
        <base-template>
            <router-view/>
        </base-template>

        <notifications :max="5" group="alert" position="top center" width="75%">
            <template slot="body" slot-scope="notification">
                <v-alert :color="notification.item.type" :value="true" dismissible>
                    <div style="color: white"><strong
                        v-if="notification.item.title" data-cy="error">{{ notification.item.title }}</strong>
                        <br>{{ notification.item.text }}
                    </div>
                </v-alert>
            </template>
        </notifications>
    </v-app>
</template>

<script>
import BaseTemplate from './templates/BaseTemplate';

export default {
    name: 'App',
    components: {
        BaseTemplate
    }
};
</script>

<style lang="scss">
.row {
    margin: 0 !important;
}
</style>
