<template>
    <base-modal :title="title">
        <v-progress-linear v-if="loading" class="my-0"
                           height="2" indeterminate/>
        <template v-else>
            <v-card-text>
                <v-form ref="form" v-model="formIsValid">
                    <v-container class="pa-0" grid-list-md>
                        <v-layout class="mt-2" row
                                  wrap>
                            <v-flex class="py-0" xs12>
                                <v-layout row wrap>
                                    <v-flex class="py-0" md6 xs12>
                                        <v-text-field
                                            v-model="form.title"
                                            :rules="[rules.required]"
                                            label="Title"
                                            prepend-icon="mdi-card-text-outline"/>
                                    </v-flex>

                                    <v-flex class="py-0" md6 xs12>
                                        <v-select
                                            v-model="form.booking_type"
                                            :items="selectable_booking_types"
                                            :rules="[rules.required]"
                                            label="Type van reserveerbaar middel"
                                            prepend-icon="mdi-wrench"/>
                                    </v-flex>

                                    <v-flex class="py-0" md12 xs12>
                                        <v-text-field
                                            v-model="form.description"
                                            label="Omschrijving"
                                            prepend-icon="mdi-card-text-outline"/>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <p class="mt-3 ml-3 red--text"></p>
                <v-spacer/>
                <v-btn class="red--text" text @click="$emit('closeModal')">
                    Annuleren
                </v-btn>
                <v-btn :disabled="!formIsValid" color="green" text @click="saveBookableItem">
                    Opslaan
                </v-btn>
            </v-card-actions>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from '../../templates/BaseModal';
import API from '../../helpers/api';
import {validationRules} from '../../mixins/validationRulesMixin';
import NotificationHelper from "../../helpers/notifications";

export default {
    name: 'BookableItemEditModal',
    components: {
        BaseModal
    },
    props: {
        bookableItemId: {
            type: Number
        }
    },
    mixins: [validationRules],
    data() {
        return {
            formIsValid: false,
            loading: false,
            form: {
                title: null,
                description: null,
                booking_type: null
            },
            selectable_booking_types: []
        }
    },
    computed: {
        isCreateMode() {
            return this.bookableItemId === undefined;
        },
        title() {
            return `Reserveerbaar middel ${this.isCreateMode ? 'aanmaken' : 'bewerken'}`;
        }
    },
    methods: {
        saveBookableItem() {
            if (!this.formIsValid) {
                NotificationHelper.showGenericError();
                return;
            }

            this.loading = true;

            const apiCall = this.isCreateMode ? API.createBookableItem(this.form) : API.updateBookableItem(this.form, this.bookableItemId);

            apiCall
                .then(() => {
                    NotificationHelper.showSuccess('Reserveerbaar middel opgeslagen', "Het reserveerbare middel is succesvol opgeslagen");
                    this.$emit('closeModal');
                })
                .catch((err) => {
                    NotificationHelper.showError('Error', err.response.data.error);
                    this.loading = false;
                });
        },
        loadBookableItem() {
            this.loading = true;

            API.getBookableItem(this.bookableItemId)
                .then(response => {
                    this.form = response.data;
                })
                .catch(() => NotificationHelper.showGenericError())
                .finally(() => this.loading = false);
        }
    },
    mounted() {
        if (!this.isCreateMode) {
            this.loadBookableItem();
        }

        API.getBookingTypes().then(response => this.selectable_booking_types = Object.keys(response.data));
    }
};
</script>
