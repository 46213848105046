import {createAxiosRequest, RM_SPA_BASE} from "../base";

const RM_ANNOUNCEMENT = `${RM_SPA_BASE}/announcement`

export default {
    getAnnouncements() {
        return createAxiosRequest(`${RM_ANNOUNCEMENT}`, 'get');
    },
    getVisibleAnnouncements() {
        return createAxiosRequest(`${RM_ANNOUNCEMENT}/get-visible`, 'get');
    },
    getAnnouncement(announcementId) {
        return createAxiosRequest(`${RM_ANNOUNCEMENT}/${announcementId}`, 'get');
    },
    deleteAnnouncement(announcementId) {
        return createAxiosRequest(`${RM_ANNOUNCEMENT}/delete/${announcementId}`, 'get');
    },
    createAnnouncement(data) {
        return createAxiosRequest(`${RM_ANNOUNCEMENT}`, 'post', data, true);
    },
    updateAnnouncement(data, announcementId) {
        return createAxiosRequest(`${RM_ANNOUNCEMENT}/${announcementId}`, 'post', data, true);
    }
}
