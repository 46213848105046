import Vue from 'vue'
import Router from 'vue-router'

import API from './helpers/api';
import NotificationHelper from './helpers/notifications';
import store from './store';
import {RM_BASE} from './helpers/resources/base';
import AnnouncementOverview from "./views/AnnouncementOverview";
import BookableItemOverview from "./views/BookableItemOverview";
import BookingOverview from "./views/BookingOverview";
import UserOverview from "./views/UserOverview";
import DashboardOverview from "./views/DashboardOverview";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/spa/announcements',
            name: 'announcements',
            component: AnnouncementOverview
        },
        {
            path: '/spa/bookable-items',
            name: 'bookable-items',
            component: BookableItemOverview
        },
        {
            path: '/spa/bookings',
            name: 'bookings',
            component: BookingOverview
        },
        {
            path: '/spa/users',
            name: 'users',
            component: UserOverview
        },
        {
            path: '/spa/dashboard',
            name: 'dashboard',
            component: DashboardOverview
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (!to.path.includes('spa')) {
        return;
    }

    if (to.path === from.path) {
        return;
    }

    API.getUserIfAuthenticated()
        .then(response => store.dispatch('setUser', response.data))
        .finally(() => performChecks(to, from, next));
});

const performChecks = (to, from, next) => {
    if (to.path === '/spa/dashboard') {
        return next();
    }

    if (!store.state.user) {
        NotificationHelper.showError("Niet ingelogd", "Log in om de applicatie te kunnen gebruiken!");
        window.location.href = `${RM_BASE}/login`;
    }

    return next();
}

export default router;
