import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        }
    },
    actions: {
        setUser(context, user) {
            if(user === false) {
                context.commit('setUser', null);
            } else {
                context.commit('setUser', user);
            }
        }
    }
});
