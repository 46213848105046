<template>
    <v-list class="pt-0">
        <template v-for="item in items">
            <v-layout
                v-if="item.heading"
                :key="item.heading"
                align-center
                row>

                <v-flex xs6>
                    <v-subheader v-if="item.heading">
                        {{ item.heading }}
                    </v-subheader>
                </v-flex>
            </v-layout>

            <v-list-group
                v-else-if="item.children && canDisplay(item)"
                :key="item.text"
                v-model="item.model"
                :append-icon="item.model ? item.icon : item['icon-alt']"
                :prepend-icon="item.icon2"
                no-action>

                <template v-slot:activator class='no-padding-left'>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </template>

                <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    :to="child.href">
                    <v-list-item-content>
                        <v-list-item-title>
                            <span :title="child.text" v-text="child.text "></span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <v-list-item v-else-if="item.children && canDisplay(item)" :key="item.text" :to="item.href">
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="item.performLogout && canDisplay(item)" :key="item.text" @click="logout">
                <v-list-item-action>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="white--text">
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import API from '../../helpers/api';
import {RM_BASE} from "../../helpers/resources/base";

export default {
    name: 'MainMenu',
    data() {
        return {
            dialog: false,
            items: [
                {icon: 'mdi-exit-to-app', text: 'Logout', href: '/logout', performLogout: true}
            ]
        };
    },
    methods: {
        async logout() {
            await API.logout()
                .then(() => {
                    this.$store.dispatch('setUser', null);
                })
                .finally(() => window.location.href = RM_BASE);
        },
        canDisplay(item) {
            if (this.$store.state.user === null) {
                return false;
            }

            return true;
        }
    }
};
</script>
