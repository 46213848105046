var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-4 pt-2 pb-2"},[_c('v-layout',{attrs:{"row":"","wrap":"","align-start":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg3":"","xl2":""}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"search","label":"Zoeken","hide-details":""},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"must-sort":"","headers":_vm.headers,"items":_vm.users,"search":_vm.filterSearch,"footer-props":_vm.rowsPerPage,"custom-filter":_vm.tableUserFilter},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"color":"error","icon":"warning"}},[_vm._v("\n\t\t\t\tEr zijn geen resultaten om hier te tonen :(\n\t\t\t")])]},proxy:true},{key:"item.name",fn:function(ref){
var value = ref.value;
return [_c('td',[_vm._v(_vm._s(value))])]}},{key:"item.email",fn:function(ref){
var value = ref.value;
return [_c('td',[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},nativeOn:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("mdi-toolbox")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',{attrs:{"dense":""}},[(_vm.adminEdit || _vm.$store.state.user.id === item.id)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('editUser', item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Gebruiker wijzigen")])],1)],1):_vm._e(),_vm._v(" "),(_vm.adminEdit)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('deleteUser', item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Gebruiker verwijderen")])],1)],1):_vm._e()],1)],1)],1)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"color":"error","icon":"warning"}},[_vm._v("\n\t\t\t\tGeen resultaten gevonden voor: '"),_c('span',{domProps:{"textContent":_vm._s(_vm.filterSearch)}}),_vm._v("'\n\t\t\t")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }