
import {createAxiosRequest, RM_BASE, RM_SPA_BASE} from '../base';

const RM_USER = `${RM_SPA_BASE}/user`

export default {
    createUser(data) {
        return createAxiosRequest(RM_USER, 'post', data);
    },
    deleteUser(userId) {
        return createAxiosRequest(`${RM_USER}/delete/${userId}`, 'get');
    },
	updateUser(data, userId) {
		return createAxiosRequest(`${RM_USER}/${userId}`, 'post', data);
	},
	getUser(userId) {
		return createAxiosRequest(`${RM_USER}/single/${userId}`, 'get');
	},
	getUsers() {
		return createAxiosRequest(`${RM_USER}`, 'get');
	},
	logout() {
		return createAxiosRequest(`${RM_BASE}/logout`, 'post');
	}
}
