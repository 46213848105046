<template>
    <div>
        <div class="px-4 pt-2 pb-2">
            <v-layout align-start row wrap>
                <v-flex lg3 md4 sm6 xl2 xs12>
                    <v-text-field v-model="filterSearch"
                                  append-icon="search"
                                  class="mr-3"
                                  hide-details
                                  label="Zoeken"/>
                </v-flex>
            </v-layout>
        </div>

        <v-divider/>

        <v-data-table :custom-filter="tableAnnouncementFilter"
                      :footer-props="rowsPerPage"
                      :headers="headers"
                      :items="announcements"
                      :search="filterSearch"
                      must-sort>
            <template v-slot:no-data>
                <v-alert color="error" icon="warning">
                    Er zijn geen resultaten om hier te tonen :(
                </v-alert>
            </template>

            <template v-slot:item.title="{ value }">
                <td>{{ value }}</td>
            </template>
            <template v-slot:item.priority="{ value }">
                <td>
                    <span v-if="value === 2">Laag</span>
                    <span v-else>Hoog</span>
                </td>
            </template>
            <template v-slot:item.display_start="{ item, value }">
                <td :class="`${isAnnouncementVisible(item) ? 'green--text' : ''}`">Van
                    {{ getDate(item.display_start) }} tot
                    {{ getDate(item.display_end) }}
                </td>
            </template>
            <template v-slot:item.created_at="{ value }">
                <td>{{ getDate(value) }}</td>
            </template>
            <template v-slot:item.actions="{ item }">
                <td>
                    <v-menu
                        bottom
                        origin="center center"
                        transition="scale-transition">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" text @click.native.stop>
                                <v-icon>mdi-toolbox</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item v-if="$store.state.user.is_admin === 1"
                                         @click.stop.prevent="$emit('editAnnouncement', item)">
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Mededeling wijzigen</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="$store.state.user.is_admin === 1"
                                         @click.stop.prevent="$emit('deleteAnnouncement', item)">
                                <v-list-item-icon>
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Mededeling verwijderen</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </td>
            </template>

            <template v-slot:no-results>
                <v-alert color="error" icon="warning">
                    Geen resultaten gevonden voor: '<span v-text="filterSearch"></span>'
                </v-alert>
            </template>
        </v-data-table>

    </div>
</template>

<script>
import {defaultTableMixin} from '../../mixins/defaultTableMixin';
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';

export default {
    name: 'AnnouncementOverviewTable',
    mixins: [defaultTableMixin],
    props: {
        announcements: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            headers: this.tableHeaders(),
            filterSearch: ''
        };
    },
    methods: {
        tableHeaders() {
            return [
                {text: 'Title', value: 'title'},
                {text: 'Prioriteit', value: 'priority'},
                {text: 'Zichtbaarheid', value: 'display_start'},
                {text: 'Aanmaakdatum', value: 'created_at'},
                {text: 'Acties', value: 'actions'}

            ];
        },
        tableAnnouncementFilter(value, search, item) {
            const normalizedSearchTerm = search.toString().toLowerCase().trim();

            return this.isMatchingName(item.title, normalizedSearchTerm);
        },
        isAnnouncementVisible(announcement) {
            return dayjs().isBetween(announcement.display_start, announcement.display_end);
        },
        getDate(date) {
            return dayjs(date).format('DD-MM-YYYY')
        }
    },
    mounted() {
        dayjs.extend(isBetween);
    }
};
</script>
