require('./bootstrap');

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './helpers/vuetify';
import store from './store';

import Notifications from 'vue-notification';
Vue.use(Notifications);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#spa')
