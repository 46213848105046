
import { createAxiosRequest, RM_SPA_BASE } from '../base';
import store from '../../../store';

const RM_BOOKABLE_ITEM = `${RM_SPA_BASE}/bookable-item`

export default {
	getBookableItems() {
		return createAxiosRequest(`${RM_BOOKABLE_ITEM}`, 'get');
	},
	createBookableItem(data) {
		return createAxiosRequest(`${RM_BOOKABLE_ITEM}`, 'post', data);
	},
	updateBookableItem(data, bookableItemId) {
		return createAxiosRequest(`${RM_BOOKABLE_ITEM}/${bookableItemId}`, 'post', data);
	},
	getBookableItem(bookableItemId) {
		return createAxiosRequest(`${RM_BOOKABLE_ITEM}/single/${bookableItemId}`, 'get');
	},
	deleteBookableItem(bookableItemId) {
		return createAxiosRequest(`${RM_BOOKABLE_ITEM}/delete/${bookableItemId}`, 'get');
	},
	getBookingTypes() {
		return createAxiosRequest(`${RM_BOOKABLE_ITEM}/booking-types`, 'get');
	}
}
