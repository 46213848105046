<template>
	<v-toolbar flat class="content-wrapper__toolbar" dark color="#e8756a"
	           style="border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;">
		<v-toolbar-title v-text="title">
		</v-toolbar-title>

		<slot name="breadcrumbs"></slot>

		<v-spacer></v-spacer>

		<slot name="buttons"></slot>

	</v-toolbar>
</template>

<script>
	export default {
		name: 'PageHeaderToolbar',
		props: ['title']
	};
</script>

<style lang="scss">
	.content-wrapper__toolbar .v-btn.secondary--text:not(:hover)::before {
		background-color: transparent !important;
	}
</style>
