<template>
    <v-container fluid>
        <v-row dense>
            <v-alert type="warning" v-if="announcements.length === 0">
                <strong>Geen mededelingen</strong><br>
                Kijk hier later nog een keer.
            </v-alert>
            <v-col cols="12" md="4" sm="6"
                   v-for="(announcement, a) in announcements"
                    :key="a">
                <v-card>

                    <v-card-title>
                        <p>{{ announcement.title }}</p>
                        <v-spacer/>
                        <v-icon v-if="announcement.priority === 1" color="red">
                            mdi-exclamation-thick
                        </v-icon>
                    </v-card-title>

                    <v-card-text>
                        <p>
                            <strong>Inhoud:</strong><br>
                            <span v-html="announcement.description"/>
                            <br>
                            <br>
                            <strong v-if="announcement.url !== null && announcement.url !== 'https://'">Link:</strong> <a v-if="announcement.url !== null && announcement.url !== 'https://'" :href="`${announcement.url}`" target="_blank">{{ announcement.url }}</a><br v-if="announcement.url !== null  && announcement.url !== 'https://'">
                            <strong>Zichtbaar t/m:</strong> {{ getDate(announcement.display_end) }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import API from "../../helpers/api";
import dayjs from "dayjs";

export default {
    name: "TabDashboardAnnouncements",
    data() {
        return {
            announcements: []
        }
    },
    methods: {
        getDate(date) {
            return dayjs(date).format('DD-MM-YYYY')
        }
    },
    mounted() {
        API.getVisibleAnnouncements()
            .then(response => this.announcements = response.data);
    }
}
</script>

<style scoped>

</style>
