var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-4 pt-2 pb-2"},[_c('v-layout',{attrs:{"align-start":"","row":"","wrap":""}},[_c('v-flex',{attrs:{"lg3":"","md4":"","sm6":"","xl2":"","xs12":""}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"search","hide-details":"","label":"Zoeken"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"custom-filter":_vm.tableAnnouncementFilter,"footer-props":_vm.rowsPerPage,"headers":_vm.headers,"items":_vm.announcements,"search":_vm.filterSearch,"must-sort":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"color":"error","icon":"warning"}},[_vm._v("\n                Er zijn geen resultaten om hier te tonen :(\n            ")])]},proxy:true},{key:"item.title",fn:function(ref){
var value = ref.value;
return [_c('td',[_vm._v(_vm._s(value))])]}},{key:"item.priority",fn:function(ref){
var value = ref.value;
return [_c('td',[(value === 2)?_c('span',[_vm._v("Laag")]):_c('span',[_vm._v("Hoog")])])]}},{key:"item.display_start",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{class:("" + (_vm.isAnnouncementVisible(item) ? 'green--text' : ''))},[_vm._v("Van\n                "+_vm._s(_vm.getDate(item.display_start))+" tot\n                "+_vm._s(_vm.getDate(item.display_end))+"\n            ")])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_c('td',[_vm._v(_vm._s(_vm.getDate(value)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},nativeOn:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("mdi-toolbox")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',{attrs:{"dense":""}},[(_vm.$store.state.user.is_admin === 1)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('editAnnouncement', item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Mededeling wijzigen")])],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.user.is_admin === 1)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('deleteAnnouncement', item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Mededeling verwijderen")])],1)],1):_vm._e()],1)],1)],1)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"color":"error","icon":"warning"}},[_vm._v("\n                Geen resultaten gevonden voor: '"),_c('span',{domProps:{"textContent":_vm._s(_vm.filterSearch)}}),_vm._v("'\n            ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }