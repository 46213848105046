<template>
    <div class="editor">
        <div class="menubar">
            <v-btn :class="`menubar__button ${ editor.isActive('bold') ? 'menubar__button--is-active' : '' }`"
                   small
                   @click.stop.prevent="editor.chain().focus().toggleBold().run()">
                <v-icon>mdi-format-bold</v-icon>
            </v-btn>

            <v-btn :class="`menubar__button ${ editor.isActive('italic') ? 'menubar__button--is-active' : '' }`"
                   small
                   @click.stop.prevent="editor.chain().focus().toggleItalic().run()">
                <v-icon>mdi-format-font</v-icon>
            </v-btn>

            <v-btn :class="`menubar__button ${ editor.isActive('strike') ? 'menubar__button--is-active' : '' }`"
                   small
                   @click.stop.prevent="editor.chain().focus().toggleStrike().run()">
                <v-icon>mdi-format-strikethrough</v-icon>
            </v-btn>

            <v-btn :class="`menubar__button ${ editor.isActive('underline') ? 'menubar__button--is-active' : '' }`"
                   small
                   @click.stop.prevent="editor.chain().focus().toggleUnderline().run()">
                <v-icon>mdi-format-underline</v-icon>
            </v-btn>

            <v-btn :class="`menubar__button ${ editor.isActive('bulletList') ? 'menubar__button--is-active' : '' }`"
                   small
                   @click.stop.prevent="editor.chain().focus().toggleBulletList().run()">
                <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>

            <v-btn :class="`menubar__button ${ editor.isActive('orderedList') ? 'menubar__button--is-active' : '' }`"
                   small
                   @click.stop.prevent="editor.chain().focus().toggleOrderedList().run()">
                <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>

            <v-btn class="menubar__button"
                   small
                   @click.stop.prevent="editor.chain().focus().setHorizontalRule().run()">
                <v-icon>mdi-format-align-middle</v-icon>
            </v-btn>

            <v-btn :class="`menubar__button ${ editor.isActive('link') ? 'menubar__button--is-active' : '' }`"
                   small
                   @click.stop.prevent="setLink()">
                <v-icon>mdi-link-plus</v-icon>
            </v-btn>

            <v-btn v-if="editor.isActive('link')"
                   class="menubar__button"
                   small
                   @click.stop.prevent="editor.chain().focus().unsetLink().run()">
                <v-icon>mdi-link-off</v-icon>
            </v-btn>

            <v-menu
                top
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                    <v-btn class="menubar__button"
                           small
                           v-on="on">
                        <v-icon>mdi-format-color-fill</v-icon>
                    </v-btn>
                </template>

                <v-color-picker :value="editor.getAttributes('textStyle').color"
                                class="ma-2"
                                hide-canvas
                                hide-inputs
                                show-swatches
                                swatches-max-height="300px"

                                width="100%"
                                @update:color="color => editor.chain().focus().setColor(color.hex).run()"/>
            </v-menu>

            <v-btn :disabled="!editor.can().undo()"
                   class="menubar__button"
                   small
                   @click.stop.prevent="editor.chain().focus().undo().run()">
                <v-icon>mdi-undo</v-icon>
            </v-btn>

            <v-btn :disabled="!editor.can().redo()"
                   class="menubar__button"
                   small
                   @click="editor.chain().focus().redo().run()">
                <v-icon>mdi-redo</v-icon>
            </v-btn>
        </div>
        <editor-content :editor="editor" class="editor__content"/>
    </div>
</template>

<script>
import {Editor, EditorContent} from '@tiptap/vue-2'
import StarterKit from "@tiptap/starter-kit";
import {Link} from "@tiptap/extension-link";
import {Underline} from "@tiptap/extension-underline";
import TextStyle from '@tiptap/extension-text-style'
import {Color} from '@tiptap/extension-color'

export default {
    components: {
        EditorContent
    },
    props: {
        value: {
            type: String
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editor: new Editor({
                editable: this.editable,
                extensions: [
                    StarterKit,
                    Link.configure({
                        openOnClick: false
                    }),
                    Underline,
                    TextStyle,
                    Color
                ],
                content: this.value,
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML())
                },
            })
        }
    },
    methods: {
        setLink() {
            const url = window.prompt('Write down your link here', 'https://')

            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({href: url})
                .run();
        }
    },
    beforeDestroy() {
        this.editor.destroy()
    }
}
</script>

<style lang="css">
.ProseMirror {
    border: black 1px solid;
}
</style>
