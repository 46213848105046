<template>
    <base-modal :title="title">
        <v-progress-linear v-if="loading" class="my-0"
                           height="2" indeterminate/>
        <template v-else>
            <v-card-text>
                <v-form ref="form" v-model="formIsValid">
                    <v-container class="pa-0" grid-list-md>
                        <v-layout class="mt-2" row
                                  wrap>
                            <v-flex class="py-0" xs12>
                                <v-layout row wrap>
                                    <v-flex class="py-0" md6 xs12>
                                        <v-text-field
                                            v-model="form.name"
                                            :rules="[rules.required]"
                                            label="Naam"
                                            prepend-icon="mdi-card-text-outline"/>
                                    </v-flex>

                                    <v-flex v-if="isCreateMode || adminEdit" class="py-0" md6 xs12>
                                        <v-text-field
                                            v-model="form.email"
                                            :rules="[rules.required]"
                                            label="Email"
                                            prepend-icon="mdi-card-text-outline"/>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <p class="mt-3 ml-3 red--text"></p>
                <v-spacer/>
                <v-btn class="red--text" text @click="$emit('closeModal')">
                    Annuleren
                </v-btn>
                <v-btn :disabled="!formIsValid" color="green" text @click="saveUser">
                    Opslaan
                </v-btn>
            </v-card-actions>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from '../../templates/BaseModal';
import API from '../../helpers/api';
import {validationRules} from '../../mixins/validationRulesMixin';
import NotificationHelper from "../../helpers/notifications";

export default {
    name: 'UserEditModal',
    components: {
        BaseModal
    },
    props: {
        userId: {
            type: Number
        }
    },
    mixins: [validationRules],
    data() {
        return {
            formIsValid: false,
            loading: true,
            form: {
                name: null,
                email: null,
            }
        }
    },
    computed: {
        isCreateMode() {
            return this.userId === undefined;
        },
        title() {
            return `Korpslid ${this.isCreateMode ? 'aanmaken' : 'bewerken'}`;
        },
        adminEdit() {
            return this.$store.state.user.is_admin === 1;
        }
    },
    methods: {
        saveUser() {
            if (!this.formIsValid) {
                NotificationHelper.showGenericError();
                return;
            }

            this.loading = true;

            const apiCall = this.isCreateMode ? API.createUser(this.form) : API.updateUser(this.form, this.userId);

            apiCall
                .then(() => {
                    NotificationHelper.showSuccess('Korpslid opgeslagen', "Het korpslid is succesvol opgeslagen");
                    this.$emit('closeModal');
                })
                .catch((err) => {
                    NotificationHelper.showError('Error', err.response.data.error);
                    this.loading = false;
                });
        },
        async loadUser() {
            this.loading = true;

            await API.getUser(this.userId)
                .then(response => {
                    this.form = response.data;
                })
                .catch(() => NotificationHelper.showGenericError())
                .finally(() => this.loading = false);
        }
    },
    async mounted() {
        if (!this.isCreateMode) {
            await this.loadUser();
        }

        this.loading = false;
    }
};
</script>
