<template>
    <div>
        <page-header-toolbar title="Reserveerbare middelen">
            <template slot="buttons">
                <v-btn class="white--text" text v-if="$store.state.user.is_admin === 1"
                       @click="showBookableItemCreateModal = true">
                    Reserveerbaar middel aanmaken
                </v-btn>
            </template>
        </page-header-toolbar>

        <!-- CREATE MODAL -->
        <bookable-item-edit-modal v-if="showBookableItemCreateModal"
                                  v-on:closeModal="closeModal"/>

        <!-- EDIT MODAL -->
        <bookable-item-edit-modal v-if="showBookableItemEditModal"
                                  :bookable-item-id="bookableItemToEdit.id"
                                  v-on:closeModal="closeModal"/>

        <v-progress-linear v-if="loading" class="my-0"
                           height="2" indeterminate/>

        <bookable-item-overview-table v-else
                                      :bookable-items="bookableItems"
                                      v-on:deleteBookableItem="deleteBookableItem"
                                      v-on:editBookableItem="editBookableItem"/>

    </div>

</template>

<script>
import BookableItemOverviewTable from "../components/bookable-items/BookableItemOverviewTable";
import PageHeaderToolbar from "../components/menus/PageHeaderToolbar";
import BookableItemEditModal from "../components/bookable-items/BookableItemEditModal";
import API from "../helpers/api";
import NotificationHelper from "../helpers/notifications";

export default {
    name: 'BookableItemOverview',
    components: {
        BookableItemOverviewTable,
        PageHeaderToolbar,
        BookableItemEditModal
    },
    data() {
        return {
            showBookableItemCreateModal: false,
            showBookableItemEditModal: false,
            bookableItemToEdit: null,
            bookableItems: [],
            loading: false
        }
    },
    methods: {
        getBookableItems() {
            this.loading = true;

            API.getBookableItems()
                .then(data => this.bookableItems = data.data)
                .catch(() => NotificationHelper.showGenericError())
                .finally(() => this.loading = false);
        },
        editBookableItem(bar) {
            this.bookableItemToEdit = bar;
            this.showBookableItemEditModal = true;
        },
        deleteBookableItem(bar) {
            this.loading = true;

            API.deleteBookableItem(bar.id)
                .then(data => {
                    NotificationHelper.showSuccess('Reserveerbaar middel verwijderd', 'Het reserveerbare middel is succesvol verwijderd!');
                    this.getBookableItems();
                })
                .catch(() => {
                    this.loading = false;
                    NotificationHelper.showGenericError()
                });
        },
        closeModal() {
            this.showBookableItemCreateModal = false;
            this.showBookableItemEditModal = false;
            this.bookableItemToEdit = null;
            this.getBookableItems();
        }
    },
    mounted() {
        this.getBookableItems();
    }
}
</script>
