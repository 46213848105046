<template>
	<div>
		<div class="px-4 pt-2 pb-2">
			<v-layout row wrap align-start>
				<v-flex xs12 sm6 md4 lg3 xl2>
					<v-text-field v-model="filterSearch"
					              append-icon="search"
					              label="Zoeken"
					              hide-details
					              class="mr-3" />
				</v-flex>
			</v-layout>
		</div>

		<v-divider/>

		<v-data-table must-sort
		              :headers="headers"
		              :items="bookableItems"
		              :search="filterSearch"
		              :footer-props="rowsPerPage"
		              :custom-filter="tableBookableItemFilter">
			<template v-slot:no-data>
				<v-alert color="error" icon="warning">
					Er zijn geen resultaten om hier te tonen :(
				</v-alert>
			</template>

			<template v-slot:item.title="{ value }">
				<td>{{ value }}</td>
			</template>

			<template v-slot:item.description="{ value }">
				<td>{{ value }}</td>
			</template>

			<template v-slot:item.booking_type="{ value }">
				<td>{{ value }}</td>
			</template>

			<template v-slot:item.actions="{ item }">
				<td>
					<v-menu
				      	bottom
				      	origin="center center"
				      	transition="scale-transition">
				      <template v-slot:activator="{ on }">
				        <v-btn @click.native.stop text v-on="on">
				        	<v-icon>mdi-toolbox</v-icon>
				    	</v-btn>
				      </template>

				      <v-list dense>
				        <v-list-item @click.stop.prevent="$emit('editBookableItem', item)"
                                     v-if="$store.state.user.is_admin === 1">
			        		<v-list-item-icon>
				            	<v-icon>mdi-pencil</v-icon>
				          	</v-list-item-icon>
				          	<v-list-item-content>
				          		<v-list-item-title>Reserveerbaar middel wijzigen</v-list-item-title>
			          		</v-list-item-content>
				        </v-list-item>
				        <v-list-item @click.stop.prevent="$emit('deleteBookableItem', item)"
                                     v-if="$store.state.user.is_admin === 1">
			        		<v-list-item-icon>
				            	<v-icon>mdi-trash-can-outline</v-icon>
				          	</v-list-item-icon>
				          	<v-list-item-content>
				          		<v-list-item-title>Reserveerbaar middel verwijderen</v-list-item-title>
			          		</v-list-item-content>
				        </v-list-item>
				      </v-list>
				    </v-menu>
				</td>
			</template>

			<template v-slot:no-results>
				<v-alert color="error" icon="warning">
					Geen resultaten gevonden voor: '<span v-text="filterSearch"></span>'
				</v-alert>
			</template>
		</v-data-table>

	</div>
</template>

<script>
	import {defaultTableMixin} from '../../mixins/defaultTableMixin';

	export default {
		name: 'BookableItemOverviewTable',
		mixins: [defaultTableMixin],
		props: {
			bookableItems: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				headers: this.tableHeaders(),
				filterSearch: ''
			};
		},
		methods: {
			tableHeaders() {
				return [
					{text: 'Title', value: 'title'},
					{text: 'Omschrijving', value: 'description'},
					{text: 'Type', value: 'booking_type'},
					{text: 'Acties', value: 'actions'}

				];
			},
			tableBookableItemFilter(value, search, item) {
				const normalizedSearchTerm = search.toString().toLowerCase().trim();

				return this.isMatchingName(item.title, normalizedSearchTerm);
			}
		}
	};
</script>
