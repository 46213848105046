import {createAxiosRequest, RM_SPA_BASE} from '../base';

const RM_BOOKING = `${RM_SPA_BASE}/booking`

export default {
    createBooking(data) {
        return createAxiosRequest(`${RM_BOOKING}/create`, 'PUT', data);
    },
    isBookingAvailable(data) {
        return createAxiosRequest(`${RM_BOOKING}/availability`, 'PUT', data);
    },
    getAllBookings(startDate, endDate) {
        const params = new URLSearchParams();
        params.append('start', startDate);
        params.append('end', endDate);
        return createAxiosRequest(`${RM_BOOKING}/get-all?${params}`, 'GET');
    },
    deleteBooking(bookingId) {
        return createAxiosRequest(`${RM_BOOKING}/delete/${bookingId}`, 'GET');
    }
}
