
import { createAxiosRequest, RM_BASE } from './base';

import announcementEndpoints from './user/announcement'
import userEndpoints from './user/user'
import bookableItemEndpoints from './user/bookable-item'
import bookingEndpoints from './user/booking'

const RM_GET_USER_ID_AUTHENTICATED = `${RM_BASE}/get-user-if-authenticated`

export default {
	getUserIfAuthenticated() {
		return createAxiosRequest(RM_GET_USER_ID_AUTHENTICATED, 'get');
	},
	...announcementEndpoints,
	...userEndpoints,
	...bookableItemEndpoints,
	...bookingEndpoints
}
