export const defaultTableMixin = {
	data() {
		return {
			rowsPerPage: { 'items-per-page-options': [25, 50, 100, 250, 500] }
		};
	},
	methods: {
		isMatchingName(name, search) {
			return name.toString().toLowerCase().includes(search);
		}
	}
};
